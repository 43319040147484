import 'isomorphic-fetch'
import * as _ from 'lodash'
import { CRM_TYPES } from '../../constants/crm-types-tags'
import {
  StandardField,
  TaggedField,
  Field,
  CustomField,
  AttachmentField,
  SubscribeField,
  AdditionalField,
} from '../../types/domain-types'
import { FormsFieldPreset } from '../../constants/field-types'

const FIELD_TYPE = {
  RATING: 'RatingsInput',
  CHECKBOX: 'Checkbox',
  CHECKBOX_GROUP: 'CheckboxGroup',
  DATE: 'DatePicker',
}

const FIELD_VALUE_TYPE = {
  RATING: 'rating',
  CHECKBOX: 'checkbox',
  DATE: 'date',
  SUBSCRIBE: 'subscribe',
  STRING: 'string',
  ATTACHMENT: 'attachmentV2',
  ADDITIONAL: 'additional',
  CUSTOM: 'custom',
}

const getFieldType = field => field.type.split('.')[1]

const getFieldValueType = field => {
  const fieldType = getFieldType(field)

  switch (fieldType) {
    case FIELD_TYPE.RATING:
      return FIELD_VALUE_TYPE.RATING
    case FIELD_TYPE.CHECKBOX:
      return FIELD_VALUE_TYPE.CHECKBOX
    case FIELD_TYPE.DATE:
      return FIELD_VALUE_TYPE.DATE
    default:
      return FIELD_VALUE_TYPE.STRING
  }
}

const getFieldValue = field => {
  const fieldType = getFieldType(field)

  switch (fieldType) {
    case FIELD_TYPE.RATING:
      if (field.value) {
        return Number(field.value)
      }
      break
    case FIELD_TYPE.CHECKBOX:
      return field.checked
    case FIELD_TYPE.CHECKBOX_GROUP:
      return _.map(field.value, (val: string) => _.replace(val, ',', ' ')).join(', ')
    case FIELD_TYPE.DATE:
      const { value } = field
      if (!value) return
      const padZero = num => _.padStart(num, 2, '0')
      return `${value.getFullYear()}-${padZero(value.getMonth() + 1)}-${padZero(value.getDate())}`
    default:
      return field.value
  }
}

const standardField = (field): StandardField => ({ value: getFieldValue(field) })

const taggedField = (field): TaggedField => {
  const dto = standardField(field)

  const tag = getCrmTag(field)

  if (tag) {
    dto['tag'] = tag
  }

  return dto
}

const attachmentField = (field, attachments): AttachmentField => {
  const fileName = _.get(field, 'value[0].name')
  const fileUrl = _.get(_.find(attachments, { name: fileName }), 'url')

  return fileUrl
    ? {
        attachment: {
          name: fileName,
          url: fileUrl,
        },
      }
    : null
}

const subscribeField = (field): SubscribeField => {
  const valueType = getFieldValueType(field)

  return {
    [valueType]: getFieldValue(field),
  }
}

const generalField = (
  field,
  attachments
): AdditionalField | CustomField | AttachmentField | SubscribeField => {
  const crmFieldType = getFieldPreset(field)

  if (crmFieldType === FormsFieldPreset.GENERAL_SUBSCRIBE) {
    return subscribeField(field)
  }

  if (crmFieldType === FormsFieldPreset.GENERAL_UPLOAD_BUTTON) {
    return attachmentField(field, attachments)
  }

  const valueType = getFieldValueType(field)

  const fieldDto: CustomField | AdditionalField = {
    value: {
      [valueType]: getFieldValue(field),
    },
  }

  const customFieldId = getCustomFieldId(field)

  if (customFieldId) {
    fieldDto['customFieldId'] = customFieldId
  }

  return fieldDto
}

const getCrmType = field => _.get(field, 'connectionConfig.crmType')
const getCustomFieldId = field => _.get(field, 'connectionConfig.customFieldId')
const getCrmLabel = field => _.get(field, 'connectionConfig.crmLabel')
const getCrmTag = field => _.get(field, 'connectionConfig.crmTag')
const getFieldPreset = field => _.get(field, 'connectionConfig.fieldType')

const fieldDtoValueHandlerByCrmType = {
  [CRM_TYPES.FIRST_NAME]: field => standardField(field),
  [CRM_TYPES.LAST_NAME]: field => standardField(field),
  [CRM_TYPES.COMPANY]: field => standardField(field),
  [CRM_TYPES.POSITION]: field => standardField(field),
  [CRM_TYPES.EMAIL]: field => taggedField(field),
  [CRM_TYPES.PHONE]: field => taggedField(field),
  [CRM_TYPES.ADDRESS]: field => taggedField(field),
  [CRM_TYPES.DATE]: field => taggedField(field),
  [CRM_TYPES.WEBSITE]: field => taggedField(field),
}

const convertToServerFieldType = field => {
  const crmType = getCrmType(field)

  switch (crmType) {
    case CRM_TYPES.CUSTOM_FIELD:
      const crmFieldType = getFieldPreset(field)
      switch (crmFieldType) {
        case FormsFieldPreset.GENERAL_SUBSCRIBE:
          return FIELD_VALUE_TYPE.SUBSCRIBE
        case FormsFieldPreset.GENERAL_UPLOAD_BUTTON:
          return FIELD_VALUE_TYPE.ATTACHMENT
        default:
          const customFieldId = getCustomFieldId(field)
          return _.isEmpty(customFieldId) ? FIELD_VALUE_TYPE.ADDITIONAL : FIELD_VALUE_TYPE.CUSTOM
      }
    default:
      return crmType
  }
}

export const createFieldDto = (field, attachments = {}): Field => {
  const crmType = getCrmType(field)

  const fieldValueHandler = fieldDtoValueHandlerByCrmType[crmType]
  const fieldValue = fieldValueHandler ? fieldValueHandler(field) : generalField(field, attachments)

  const crmLabel = getCrmLabel(field)
  const serverCrmType = convertToServerFieldType(field)

  return {
    label: crmLabel,
    [serverCrmType]: fieldValue,
  }
}
