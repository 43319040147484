import * as _ from 'lodash'
import { FormStrategy } from './form-strategy'
import { FIELDS, ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG } from '../../constants/roles'
import { FormsFieldPreset } from '../../constants/field-types'
import { escapeRegExp, innerText } from '../viewer-utils'
import { CRM_TYPES } from '../../constants/crm-types-tags'
import * as Raven from 'raven-js'
import { getInputValue } from '../input-value'
import { getFieldType, isNumber } from '../viewer-utils'

const ERROR_COLOR = '#FF4040'
const PASSWORD_LENGTH = { MIN: 4, MAX: 15 }

const getFields = $w => {
  const email: any = loginEmailField($w)
  const password: any = passwordField($w)
  return { email, password }
}

const loginEmailField = ($w): any => _($w(`@${FIELDS.ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL}`)).first()
const passwordField = ($w): any => _($w(`@${FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD}`)).first()
const linkLoginField = ($w): any => _($w(`@${ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG}`)).first()

const submitFields = fields =>
  fields.filter(field => _.get(field, 'connectionConfig.fieldType') !== 'password')

const IGNORED_FIELDS = {
  [FormsFieldPreset.REGISTRATION_FORM_PASSWORD]: true,
  [FormsFieldPreset.REGISTRATION_FORM_CHECKBOX_AGREE_TERMS]: true,
  [FormsFieldPreset.REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY]: true,
  [FormsFieldPreset.REGISTRATION_FORM_LOGIN_EMAIL]: true,
}

const showExistingAccountMessage = (message, { messageContent }) => {
  if (!_.get(message, 'html')) {
    return
  }

  const colorRegExp = /color: ?[^;"]+/
  let htmlErrorMessage = messageContent

  if (message.html.indexOf(colorRegExp) === -1) {
    htmlErrorMessage = `<span style="color: ${ERROR_COLOR}">${htmlErrorMessage}</span>`
  }

  message.html = message.html
    .replace(colorRegExp, `color: ${ERROR_COLOR}`)
    .replace(new RegExp(`>${escapeRegExp(innerText(message.html))}`), `>${htmlErrorMessage}`)

  message.show()
}

const valueHandlerByType = {
  DatePicker: ({ value }) => value
}

const getFieldValue = field => {
  const fieldType = getFieldType(field)
  const fieldValue = valueHandlerByType[fieldType] ? valueHandlerByType[fieldType](field) : getInputValue(field)
  return isNumber(field) ? +fieldValue : fieldValue
}

const buildContactInfo = validFields => {
  const contactInfo = {
    phones: [],
    emails: []
  }

  validFields.forEach(field => {
    const { crmType, customFieldId, customFieldName } = field.connectionConfig
    const fieldValue = getFieldValue(field)
    switch (crmType) {
      case CRM_TYPES.EMAIL:
        contactInfo.emails.push(fieldValue)
        break
      case CRM_TYPES.PHONE:
        contactInfo.phones.push(fieldValue)
        break
      case CRM_TYPES.CUSTOM_FIELD:
        if (customFieldId) {
          contactInfo[customFieldName] = fieldValue
        }
        break
      default:
        contactInfo[crmType] = fieldValue
    }
  })
  return contactInfo;
}

export class RegistrationFormStrategy extends FormStrategy {
  firstInitialization = {
    [FIELDS.ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL]: true,
    [FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD]: true
  }

  static isEnabled($w) {
    const { email, password } = getFields($w)
    return email && password
  }

  constructor(submitArgs, initInstance, linksUtil) {
    super(submitArgs, initInstance, linksUtil)
    const { $w, wixUsers, wixWindow } = submitArgs

    this.registerPasswordValidation($w)
    this.registerLoginLink({ $w, wixUsers, wixWindow })
  }

  registerPasswordValidation($w) {
    const password = passwordField($w)

    if (!password)
      return

    password.onCustomValidation((value, reject) => {
      if (this.firstInitialization[FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD]) {
        this.firstInitialization[FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD] = false
        return
      }

      if (value.length < PASSWORD_LENGTH.MIN || value.length > PASSWORD_LENGTH.MAX) {
        reject(`Password length must be between ${PASSWORD_LENGTH.MIN} and ${PASSWORD_LENGTH.MAX} characters.`)
      }
    })

    password.onBlur(() => {
      if (!this.firstInitialization) {
        password.valid
      }
    })
  }

  registerLoginEmailValidation($w) {
    const email = loginEmailField($w)

    if (!email)
      return


    email.onCustomValidation((value: string, reject) => {
      if (this.firstInitialization[FIELDS.ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL]) {
        this.firstInitialization[FIELDS.ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL] = false
        return
      }

      // This is not the best regex - sync correctly with https://github.com/wix-private/crm/blob/7a3f0926062cd3068db5cb95485e09cc605bae75/wix-contacts-server/wix-contacts-email-validator/src/main/scala/com/wixpress/contacts/emailvalidator/EmailValidator.scala#L16
      if (!value.match(/[^@]+@[^\.]+\..+/ig)) {
        reject(`Invalid email address`)
      }
    })

    email.onBlur(() => {
      if (!this.firstInitialization) {
        email.valid
      }
    })
  }

  registerLoginLink({$w, wixUsers, wixWindow}) {
    const loginLink = linkLoginField($w)

    if (!loginLink)
      return

    loginLink.onClick(() =>
      wixUsers
        .promptLogin({ mode: 'login' })
        .then(() => wixWindow.lightbox.close())
        .catch(() => wixWindow.lightbox.close())
    )
  }

  async execute({ attachments, fields }) {
    const { $w, wixUsers, wixWindow, $message } = this.submitArgs
    const { email, password } = getFields($w)
    const validFields = fields.filter(field => field.connectionConfig.crmType &&
      !_.isEmpty(getInputValue(field)) &&
      !IGNORED_FIELDS[field.connectionConfig.fieldType])

    const contactInfo = buildContactInfo(validFields)

    try {
      await wixUsers.register(email.value, password.value, { contactInfo, defaultFlow: true })
      wixWindow.lightbox.close()
    } catch (e) {
      Raven.captureException(new Error('Something went wrong while attempting to register'))
      await showExistingAccountMessage($message, {
        messageContent: 'A member with this email address already exists.',
      })
      return
    }
    return super.execute({ attachments, fields: submitFields(validFields), skipSendActivity: true })
  }

  async postSubmission() {
    const { wixWindow } = this.submitArgs
    setTimeout(() => wixWindow.lightbox.close(), 750)
  }
}
