import { CATEGORIES } from '../panels/manage-fields-panel/constants/manage-fields-constants'

// TODO: There is two different Field types (the second one in field-types-data)
export interface Field {
  value: FieldPreset
  name: string
  group: string
  behaviorType: FieldBehaviorType
  customFieldId?: string
  isPremium?: boolean
  dependsOn?: string[]
  tooltip?: string
  subCategory?: CATEGORIES
  category?: CATEGORIES
}

export enum FieldBehaviorType {
  INPUT = 'FIELD',
  CONNECTED_ELEMENT = 'CONNECTED_ELEMENT',
}

export enum MessageType {
  HIDDEN = 'HIDDEN',
  REGISTRATION = 'REGISTRATION',
}

export enum ButtonType {
  SUBMIT = 'SUBMIT',
  SIGNUP = 'SIGNUP',
}

export type FieldPreset = FormsFieldPreset | AdiFieldPreset | ConnectedElementPreset

export enum FormsFieldPreset {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  COMPANY = 'company',
  POSITION = 'position',
  EMAIL = 'email',
  ADDRESS = 'address',
  PHONE = 'phone',
  BIRTHDAY = 'birthday',
  ANNIVERSARY = 'anniversary',
  WEBSITE = 'website',
  GENERAL_TEXT = 'generalText',
  GENERAL_NUMBER = 'generalNumber',
  GENERAL_URL = 'generalURL',
  GENERAL_RADIO_BUTTON = 'generalRadioButton',
  GENERAL_DROP_DOWN = 'generalDropDown',
  GENERAL_CHECKBOX = 'generalCheckbox',
  GENERAL_SINGLE_CHECKBOX = 'generalSingleCheckbox',
  GENERAL_SUBSCRIBE = 'generalSubscribe',
  GENERAL_TEXT_BOX = 'generalTextBox',
  GENERAL_UPLOAD_BUTTON = 'generalUploadButton',
  GENERAL_DATE_PICKER = 'generalDatePicker',
  GENERAL_RATING = 'generalRating',
  GENERAL_RECAPTCHA = 'generalRecaptcha',
  CRM_TEXT = 'crmText',
  CRM_NUMBER = 'crmNumber',
  CRM_URL = 'crmURL',
  CRM_DATE = 'crmDate',
  REGISTRATION_FORM_LOGIN_EMAIL = 'regForm_loginEmail',
  REGISTRATION_FORM_PASSWORD = 'regForm_password',
  REGISTRATION_FORM_CHECKBOX_AGREE_TERMS = 'regForm_checkboxAgreeTerms',
  REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY = 'regForm_checkboxJoinCommunity',
  NONE = ''
}

export enum AdiFieldPreset {
  ADI_EMAIL = 'adiEmail',
  ADI_ADDRESS = 'adiAddress',
  ADI_PHONE = 'adiPhone',
  ADI_URL = 'adiURL',
  ADI_DATE = 'adiDate',
  ADI_NUMBER = 'adiNumber',
  ADI_FIRST_NAME = 'adiFirstName',
  ADI_LAST_NAME = 'adiLastName',
}

export enum ConnectedElementPreset {
  REGISTRATION_FORM_LINK_TO_LOGIN = 'regForm_linkToLoginDialog',
  REGISTRATION_FORM_TERMS_OF_USE = 'regForm_emptyLinkTou',
  REGISTRATION_FORM_PRIVACY_POLICY = 'regForm_emptyLinkPrivacy',
  REGISTRATION_FORM_CODE_OF_CONDUCT = 'regForm_emptyLinkCoc',
}

export enum customTypes {
  TEXT = 'Text',
  NUMBER = 'Number',
  URL = 'URL',
  DATE = 'Date'
}

export enum FileUploaderType {
  IMAGE = 'Image',
  DOCUMNET = 'Document'
}

export type CustomField = { name: string; fieldType: customTypes }
